import React from 'react'
import { Switch, Route } from "react-router-dom";
import routes from './routes.js'
import withAuth from './middleware/withAuth'
import Navbar from './components/Nav/Nav'
import Footer from './components/Footer'

class PrivateContent extends React.Component {

  

  render () {
    return(
      <div className="flex flex-col min-h-screen bg-gray-200">
        <Navbar />

        {/* Switch it like its hot */}
        <main className="flex-grow">
          <div className="container mx-auto" style={{maxWidth: '768px'}}>
            <Switch>
             {routes.map((d, i) =>
               <Route key={i + d.name} {...d} />
             )}
            </Switch>
          </div>
        </main>
        <Footer />
      </div>
    )
  }
}

export default withAuth(PrivateContent);
