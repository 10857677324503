import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'

class Screen extends React.Component {

  componentDidMount(){
    this.checkScreen()
  }

  checkScreen(){
    const { screenId, installationId } = this.props.match.params
    const { screens } = this.props
    const screen = screens.length !== 0 ? screens.find(s => s.id == screenId) : null
    if (screen === null) {
      this.props.history.push(`/installation/${installationId}`)
    }
  }


  render () {
    const { screenId, installationId } = this.props.match.params
    const { screens } = this.props
    const screen = screens.length !== 0 ? screens.find(s => s.id == screenId) : null

    return (
      <div className="flex flex-wrap justify-center text-center">
        <p className="pt-10 p-6 w-full text-3xl text-gray-500 text-center">{screen && screen.display_name}</p>
        <div className="w-full p-4">
          <Link to={`/installation/${installationId}/screen/${screenId}/timeline/master`} className="inline-block text-center bg-gray-300 shadow w-full max-w-xs hover:shadow-lg text-black font-bold py-3 p-4 rounded-full">
            <i className=" align-middle material-icons inline-block mr-2">edit</i><p className="inline-block" >Editar el programa</p>
          </Link>
        </div>
        <div className="w-full p-4">
          <Link to={`/installation/${installationId}/screen/${screenId}/action/master`} className="inline-block text-center bg-gray-300 shadow w-full max-w-xs  hover:shadow-lg text-black font-bold py-3 p-4 rounded-full">
            <i className="align-middle material-icons inline-block mr-2">sort</i><p className="inline-block" >Selecciona la macro</p>
          </Link>
        </div>
      </div>
    )
  }
}

const mapStateToProps = function (state) {
    return {
        installations: state.installations.installations,
        screens: state.installations.screens
    };
};

export default connect(mapStateToProps)(withRouter(Screen));
