import React from 'react'

const NEXT_DOTS = {
  '': '.',
  '.': '. .',
  '. .': '. . .',
  '. . .': '',
}

function Dots(){
  const [dots, setDots] = React.useState('')

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setDots(d => NEXT_DOTS[d])
    }, 350)

    return () => clearInterval(intervalId)
  })

  return (
    <div
      css={{
        display: 'inline-block',
        textAlign: 'center',
        width: 30,
        height: 30,
      }}
    >
      {dots}
    </div>
  )
}

export default Dots;
