import React from 'react'
import Picker from 'react-mobile-picker';
import './wheelpicker.css'

function getHours(){
  var h = []
  for (var i = 0; i < 24; i++) { h.push(i.toString()) }
  return h
}

function getMinutes(steps){
  var m = []
  for (var i = 0; i < 60; i = i + steps) {
    if (i.toString().length === 1) {
      m.push(`0${i}`)
    } else {
      m.push(i.toString())
    }
  }
  return m
}

class Wheelpicker extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      valueGroups: {
        macro: '',
        h: '12',
        m: '00',
      },
      optionGroups: {
        macro: [''],
        h: getHours(),
        m: getMinutes(15)
      },
      checked: false,
    };
  }

  // Update the value in response to user picking event
  handleChange = (name, value) => {
    this.setState(({valueGroups}) => ({
      valueGroups: {
        ...valueGroups,
        [name]: value
      },
    }));
    this.props.handleChange(name, value)

  };

  componentDidMount(){
    this.insertMacros()
  }

  insertMacros(){
    const { macros } = this.props
    var { valueGroups, optionGroups } = this.state

    if (macros.length === 0) return;

    valueGroups.macro = macros[0]?.display_name
    optionGroups.macro = macros.map(d => d.display_name)

    this.setState({valueGroups: valueGroups, optionGroups: optionGroups})
  }


  render () {
    const {optionGroups, valueGroups, checked} = this.state;

    return (
      <div style={{}} className="container flex flex-wrap">
        <div className="w-full">
          <div className="w-full">
            <div className="flex flex-wrap -mx-3 mb-6">
             <div className="w-full px-3 pt-6">
               <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                 posibles macros
               </label>
               <div className="relative">
                 <select
                   onChange={(e) => this.handleChange('macro', e.target.value)}
                   value={valueGroups.macro}
                   className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                   {this.props.macros.map((d, i) =>
                     <option key={'ma-' + i} value={d.display_name}>{d.display_name}</option>
                   )}
                 </select>
                 <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                   <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                 </div>
               </div>
             </div>
           </div>
           <div className="flex flex-wrap -mx-3 mb-8">
             <div className="w-1/2 px-3">
               <div className="relative">
                 <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                   horas
                 </label>
                 <select
                   onChange={(e) => this.handleChange('h', e.target.value)}
                   value={valueGroups.h}
                   className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                   {optionGroups.h.map((d, i) =>
                     <option key={d} value={d}>{d}</option>
                   )}
                 </select>
                 <div style={{maxHeight: '44px', marginTop: '28px'}}  className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                   <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                 </div>
               </div>
             </div>
             <div className="w-1/2 px-3">
               <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                 minutos
               </label>
               <div className="relative">
                 <select
                   onChange={(e) => this.handleChange('m', e.target.value)}
                   value={valueGroups.m}
                   className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                   {optionGroups.m.map((d, i) =>
                     <option key={d} value={d}>{d}</option>
                   )}
                 </select>
                 <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                   <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                 </div>
               </div>
             </div>
           </div>
          </div>
        </div>
        {/*<div className="w-full">
          <Picker
            optionGroups={optionGroups}
            valueGroups={valueGroups}
            onChange={this.handleChange} />
        </div>*/}

      </div>

    )
  }
}

export default Wheelpicker;
