import React from 'react'
import Picker from 'react-mobile-picker';
import './wheelpicker.css'

class Wheelpicker extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      valueGroups: {
        title: '',
      },
      optionGroups: {
        title: ['', '', '', ''],
      },
      checked: false,
    };
  }

  // Update the value in response to user picking event
  handleChange = (name, value) => {
    const { data } = this.props

    this.setState(({valueGroups}) => ({
      valueGroups: {
        ...valueGroups,
        [name]: value
      },
      checked: false,
    }));

    var obj = data.filter(d => d.display_name === value)[0]
    this.props.onChange(obj.id)
  };

  componentDidMount(){
    this.insertMacros()
  }

  insertMacros(){
    const { data, selected } = this.props

    if (data === undefined) return;
    if (data.length === 0) return;

    const selectors = data.map(d => d.display_name)
    this.setState(({valueGroups}) => ({
      valueGroups: {
        ...valueGroups,
        'title': data[selected]?.display_name
      },
      optionGroups: {
        'title': selectors
      },
    }));
  }




  render () {
    const {optionGroups, valueGroups } = this.state;

    return (
      <div style={{}} className="container">
        <Picker
          optionGroups={optionGroups}
          valueGroups={valueGroups}
          onChange={this.handleChange} />
      </div>

    )
  }
}

export default Wheelpicker;
