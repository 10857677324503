import React from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import { connect } from 'react-redux'
import { toast } from 'react-toastify';

import withAuth from '../../middleware/withAuth'
import TimelinePicker from '../../components/Wheelpicker/timelinePicker'
import Loader from '../../components/Loader/Loader1'
import host from '../../const/host'

class Timeline extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      selectedTimelines : [],
      activeTimeline: null,
      scr: null,
      ts: [],
      loadingTs: false,
      activeMacro: '',
      selectedTime: '',
      valueGroups: {
        macro: '',
        h: '12',
        m: '00',
      }
    }
  }

  componentDidMount(){
    
    this.checkScreen()
  }

  async submitTimestamp(){
    const { valueGroups, selectedTimelines, activeTimeline } = this.state
    const macro = this.state.scr.possible_macros.filter(d => d.display_name === valueGroups.macro)[0]
    const timestamp = `${valueGroups.h}:${valueGroups.m}:00`
    const ts = [...this.state.ts]
    const isTimelineOpen = selectedTimelines.includes(activeTimeline)
    const user = JSON.parse(sessionStorage.getItem('user'))

    let screen_id = this.props.match.params.screenId;
    
    
    var reqs = []

    if (selectedTimelines.length === 0) return

    selectedTimelines.forEach((d, i) => {
      var obj = {
        action_time: timestamp,
        macro: macro.id,
        timeline_owner: d,
        owner: user.user.id,
        status: 'draft'
      }

      var req = axios.post(`${host}/timestamps/${screen_id}`, obj, {headers: {Authorization: `Bearer ${sessionStorage.getItem('token')}`}})
      reqs.push(req)
    })

    try {
      await Promise.all(reqs)

      if (isTimelineOpen) {
        const obj1 = {
          action_time: timestamp,
          action_macro: macro ? macro.id : 60, 
          macro: {display_name: macro.display_name},
          timeline_owner: activeTimeline
        }
        ts.push(obj1)
        this.setState({ts: ts})
      }

      toast.success("éxito")
    } catch (err) {
      toast.error(err.message)
      console.log(err)
    }

    // console.log(timestamp, macro.id, selectedTimelines, activeTimeline)
  }

  handleChange(name, value){
    this.setState(({valueGroups}) => ({
      valueGroups: {
        ...valueGroups,
        [name]: value
      },
    }));
  }

  checkScreen(){
    const { screenId, installationId } = this.props.match.params
    const { screens } = this.props
    const screen = screens.length !== 0 ? screens.find(s => s.id == screenId) : null
    if (screen === null || screen.possible_macros.length === 0) {
      this.props.history.push(`/installation/${installationId}`)
      return
    }

    var vg = this.state.valueGroups
    vg.macro = screen.possible_macros[0].display_name
    this.getTimestamps(screen.timelines[0].id)
    this.setState({scr: screen, activeTimeline: screen.timelines[0]?.id, valueGroups: vg})
  }

  selectTimeline(id){
    var timelines = [...this.state.selectedTimelines]

    if (timelines.includes(id)){
      var index = timelines.findIndex(d => d === id)
      timelines.splice(index, 1)
    } else {
      timelines.push(id)
    }

    this.setState({selectedTimelines: timelines})
  }

  setTimeline(id){
    this.setState({activeTimeline: id})
    this.getTimestamps(id)
  }

  async getTimestamps(timelineId){
    const { installationId } = this.props.match.params
    this.setState({loadingTs: true, ts: []})
    try {
      const res = await axios.get(`${host}/timestamps?[timeline_owner]=${timelineId}`, {headers: {Authorization: `Bearer ${sessionStorage.getItem('token')}`}})
      const ts = res.data

      console.log(ts)

      this.setState({ts: ts, loadingTs: false})
    } catch (err) {
      alert('error fetching timestamps: ' + err.message )
      this.setState({loadingTs: false})
      console.log(err)
    }
  }

  async removeTimestamp(id){
    var ts = [...this.state.ts]
    var i  = ts.findIndex(d => d.id === id)

    try {
      await axios.delete(`${host}/timestamps/${id}`, {headers: {Authorization: `Bearer ${sessionStorage.getItem('token')}`}})

      if (i !== -1){
        ts.splice(i, 1)
      }

      this.setState({ts:ts})
      toast.success('eliminado')

    } catch (err) {
      toast.error(err)
      console.log(err)
    }


  }

  sortTimestamps(arr){
    var ts = arr.sort(function(a, b){
      var aa = parseInt(a.action_time.replace(':', ''))
      var bb = parseInt(b.action_time.replace(':', ''))
      if(aa < bb) { return -1; }
      if(aa > bb) { return 1; }
      return 0;
    })

    return ts
  }

  render () {
    const { actionId } = this.props.match.params
    const { selectedTimelines, activeTimeline, scr, ts, loadingTs } = this.state
    const screen = scr
    const macros = screen ? screen.possible_macros : []
    const tl = screen ? screen.timelines.sort((a, b) => (a.order_index - b.order_index)) : []

    if (!screen) {
      return <></>
    }

    return (
      <div className="flex flex-wrap justify-center text-center">
        <p className="pt-10 p-6 w-full text-3xl text-gray-500 text-center">{screen && screen.display_name} Timeline</p>
        <div className="w-full p-4">
          <div className="flex align-center flex-wrap justify-between text-center bg-white mb-10 shadow-lg w-full text-black sm:px-10 py-3 px-2 p-4 rounded-lg">


            <div className="w-full">
              <TimelinePicker macros={macros} handleChange={(n, v) => this.handleChange(n, v)}/>
            </div>

            <div className="flex flex-wrap w-full justify-center py-8">
              {tl.map((d, i) =>
                <div
                  key={'tl1-' + i}
                  onClick={() => this.selectTimeline(d.id)}
                  className={`p-2 bg-gray-500 cursor-pointer text-white m-2 w-10 h-10 rounded-full ${selectedTimelines.includes(d.id) ? 'bg-gray-700' : ''}`}>
                  {d.display_name.substring(0,2)}
                </div>
              )}
            </div>
            <div className="flex w-full justify-center pb-8">
              <button
                onClick={() => this.submitTimestamp()}
                className="bg-transparent hover:bg-gray-700 text-gray-700 font-semibold hover:text-white py-2 px-4 border border-gray-700 hover:border-transparent rounded">
                añadir
              </button>
            </div>
          </div>
        </div>

        <div className="w-full p-4">
          <div className="flex align-center flex-wrap justify-between text-center bg-white mb-10 shadow-lg w-full text-black py-3 rounded-lg">
            <div className="w-full mb-4">
              <ul style={{'overflowY' : 'scroll'}} className="flex pb-4">
                {tl.map((t, i) =>
                  <li key={'tl-' + i} className="flex-1">
                    <a
                      onClick={() => this.setTimeline(t.id)}
                      className={`bg-white cursor-pointer w-full inline-block rounded-t py-2 px-4 text-gray-700 ${activeTimeline === t.id ? 'border-gray-500  border-b-2 font-semibold' : ''}`}>
                      {t.display_name.substring(0,2)}
                    </a>
                  </li>
                )}

              </ul>
            </div>

            <div className="w-full">
              <ul className="flex flex-wrap">
                {!loadingTs && this.sortTimestamps(ts).map((d, i) =>
                  <li key={'ts-' + i} className="w-full flex py-2 font-bold">
                    <div className="w-1/4 w-sm-1/3">{d.action_time.substring(0, 5)}</div>
                    <div className="w-2/4 w-sm-1/3">{d.macro?.display_name}</div>
                    <div
                      onClick={() => this.removeTimestamp(d.id)}
                      className="w-1/4 w-sm-1/3 text-red-600 cursor-pointer">
                      <i className="material-icons">remove_circle</i>
                    </div>
                  </li>
                )}
                {loadingTs ? <Loader /> : null}
              </ul>
              {!loadingTs && ts.length === 0 ?
              <p className="py-20 p-6 w-full text-3xl text-gray-500 text-center">No hay entradas todavía.</p> : ''}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = function (state) {
    return {
        installations: state.installations.installations,
        screens: state.installations.screens
    };
};

export default connect(mapStateToProps)(withAuth(withRouter(Timeline)));
