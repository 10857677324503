import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

function withAuth(ComponentToProtect){

  return class extends Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false,
      };
    }
    componentDidMount() {
      this.checkLocalStorage()
    }

    checkLocalStorage(){
      var me = JSON.parse(sessionStorage.getItem('user'))
      // console.log(me)

      if (!me) {
        this.setState({ loading: false, redirect: true })
      } else {
        this.setState({ loading: false })
      }
    }


    render() {
      const { loading, redirect } = this.state;
      if (loading) {
        return <div>...loading</div>;
      }
      if (redirect) {
        return <Redirect to="/login" />;
      }
      return <ComponentToProtect {...this.props} />;
    }
  }
}

export default withAuth;
