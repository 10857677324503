import React from 'react'
import withAuth from '../../middleware/withAuth'

class Screens extends React.Component {

  render () {
    // console.log('screens')
    return (
      <div>
        Screens
      </div>
    )
  }
}

export default withAuth(Screens);
