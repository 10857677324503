import React from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify';
import moment from 'moment'
import Dots from '../login/loadingDots'
import host from '../../const/host'

class CreateForm extends React.Component {
  constructor(props){
    super(props)  
    this.password = React.createRef();
    this.password_retry = React.createRef();
    this.state = {
      error: null,
      loading: false,
      logo: ''
    } 
  }

  componentDidMount(){
    this.getLogo()
  }

  async getLogo(){
    try {
      const res = await axios.get(`${host}/project-settings`)
      const logo = `${host}${res.data.logo.url}` 
      const favico = `${host}${res.data.favico.url}`
      this.setFavicon(favico)
      this.setState({logo: logo})
    } catch(err){
      console.log(err)
    }
  }

  setFavicon(icon){
    document.getElementById('fav-1').href = icon
    document.getElementById('fav-2').href = icon
    document.getElementById('fav-3').href = icon
    document.getElementById('fav-4').href = icon
    document.getElementById('fav-5').href = icon
  }


  async createAccount(){
    const pw = this.password.current.value
    const pw_retry = this.password_retry.current.value
    const urlParams = new URLSearchParams(this.props.location.search);
    const token = urlParams.get('reset-token') 

    console.log(token)


    this.setState({error: null})

    if (pw !== pw_retry){
      this.setState({error: 'Passwords do not match.'})
      return
    }

    if (pw === '' || pw.length < 4){
      this.setState({error: 'You need to enter a valid password with at least 4 characters.'})
      return
    }

    this.setState({loading: true})

    try {
      const req = {
        code: token,
        password: pw,
        passwordConfirmation: pw_retry,
      } 
      const res = await axios.post(`${host}/auth/reset-password`, req)
      
      toast.success('Your password has been reset.')
      this.props.history.push('/login')
    } catch (err){
      this.setState({error: 'Something went wrong.', loading: false})
      console.log(err.message)
    }
  }

  render () {
    const { error, loading, logo } = this.state

    return(
      <div className="w-full max-w-xs">
        <div  className="flex justify-center p-4 pt-0">
          <img style={{maxHeight: '50px'}} src={logo}/>
        </div>
        <form autoComplete="off" onSubmit={() => this.login()} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <p className="text-xl text-center text-gray-700 font-bold mb-6">Set Password</p>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input autoComplete='off' required ref={this.password} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="password" name="pw-1" placeholder="******************" />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password_retry">
              Repeat Password
            </label>
            <input autoComplete='off' required ref={this.password_retry} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password_retry" name="pw-2" type="password" placeholder="******************" />

            {error ? <p className="text-red-500 text-xs italic">{error}</p> : null }
          </div>
          <div className="flex items-center justify-center">
            <button
              disabled={loading}
              onClick={() => this.createAccount()}
              className="custom bg-blue-500 disabled:bg-gray-300 h-10 w-40 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline text-sm" type="button">
              {!loading ? 'Set Password' : <Dots /> }
            </button>
          </div>
        </form>
        <p className="text-center text-gray-500 text-xs">
          &copy;{moment().format('YYYY')} led&go. All rights reserved.
        </p>
      </div>
    )
  }
}

export default withRouter(CreateForm);
