import React from 'react'
import CreateForm from './CreateForm'

class Login extends React.Component {
  render () {
    return(
      <div className="p-2 flex justify-center content-center flex-wrap bg-gray-800 h-screen">
        <CreateForm />
      </div>
    )
  }
}

export default Login;
