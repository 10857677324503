const initState = {
    installations: [],
    selectedInstallation: null,
    screens: []
};

export default function installations(state = initState, action) {
  switch (action.type) {
    case 'INSERT_INSTALLATION':
      return Object.assign({}, state, {
          installations: [...action.payload, ...state.installations],
      });
    case 'SELECT_INSTALLATION':
      return Object.assign({}, state, {
          selectedInstallation: action.payload,
      });
    case 'SET_SCREENS':
      return Object.assign({}, state, {
          screens: [...action.payload],
      });
    case 'UPDATE_SCREEN':
      var tmp = [...state.screens]
      var index = state.screens.findIndex(d => d.id === action.payload.id)
      if (index !== -1) {
        tmp[index] = action.payload
      }
      return Object.assign({}, state, {
          screens: tmp,
      });
    default:
      return state;
  }
}
