import React from 'react'
import LoginForm from './LoginForm'

class Login extends React.Component {
  render () {
    return(
      <div className="p-2 flex justify-center content-center flex-wrap bg-gray-800 h-screen">
        <LoginForm />
      </div>
    )
  }
}

export default Login;
