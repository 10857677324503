const host_prod = window.location.origin;
const host_dev = 'http://localhost:1337';

let host

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    host = host_dev
} else {
    host = host_prod
}

export default host;
